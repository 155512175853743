import { ExpandMore } from "@mui/icons-material";
import CloseIcon from "@mui/icons-material/Close";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import LocalShippingIcon from "@mui/icons-material/LocalShipping";
import {
  Avatar,
  Button,
  Card,
  CardContent,
  CardHeader,
  Collapse,
  IconButton,
  TextField,
  Typography,
} from "@mui/material";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { DataGrid } from "@mui/x-data-grid";
import moment from "moment";
import React, { useState } from "react";
import { ToastContainer } from "react-toastify";
import { shallow } from "zustand/shallow";
import ButtonMUI from "../../components/Atoms/Button";
import { LocalTime } from "../../components/common/time";
import { useApi } from "../../hooks/useApi";
import Store from "../../store/loggerStore";
import VehicleListSearchSection from "./components/SearchSection";
import ManagerDashboardStore from "./store/ManagerStore";
import Loader from "../../components/common/Loader";

const ManagerDashboard = () => {
  const { user } = Store(
    (state) => ({
      user: state.user,
    }),
    shallow
  );

  const { filteredData, updateData } = ManagerDashboardStore(
    (state) => ({
      filteredData: state.filteredData,
      updateData: state.updateData,
    }),
    shallow
  );

  const { patch, loading } = useApi();

  const [open, setOpen] = useState(false);
  const [selectedRowId, setSelectedRowId] = useState(null);
  const [reject, setReject] = useState(false);
  const [rejectReason, setRejectReason] = useState("");
  const [expanded, setExpanded] = useState(false);
  const [selectedRow, SetselectedRow] = useState();

  const handleExpandClick = (item) => {
    setExpanded(!expanded);
    SetselectedRow(item._id);
  };

  const approveRequestFunc = async (status) => {
    let payload = {
      approved_by_store_manager: status,
      store_approval_time: moment.utc(),
      store_approver_name: user.name,
      store_reject_reason: rejectReason,
    };

    const response = await patch(
      `store-manager-dashboard/update-status/${selectedRowId}`,
      payload
    );

    if (!response?.error) {
      updateData(new Date().valueOf());
      setOpen(false);
      setReject(false);
    }
  };

  const columns = [
    {
      headerName: "Status",
      field: "approved_by_store_manager",
      type: "actions",
      flex: 1,
      minWidth: 150,
      maxWidth: 300,
      align: "center",
      headerAlign: "center",
      renderCell: (e) => (
        <ButtonMUI
          size="small"
          disabled={e.row?.approved_by_store_manager !== "pending"}
          onClick={() => {
            setOpen(true);
            setSelectedRowId(e.row?._id);
          }}
          text={e.row?.approved_by_store_manager}
        />
      ),
    },
    {
      field: "reg_no",
      headerName: "Registration No",
      flex: 1,
      minWidth: 150,
      maxWidth: 300,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "vendorName",
      headerName: "Vendor Name",
      flex: 1,
      minWidth: 150,
      maxWidth: 300,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "workingArea",
      headerName: "Working Area",
      flex: 1,
      minWidth: 150,
      maxWidth: 300,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "fuel_issue_time",
      headerName: "Fuel request time",
      flex: 1,
      minWidth: 150,
      maxWidth: 300,
      align: "center",
      headerAlign: "center",
      renderCell: (e) => LocalTime(e.row?.fuel_issue_time),
    },

    {
      field: "vehicleType",
      headerName: "Vehicle Type",
      flex: 1,
      minWidth: 150,
      maxWidth: 300,
      align: "center",
      headerAlign: "center",
    },

    {
      field: "fuel_vol",
      headerName: "Requested Volume",
      flex: 1,
      minWidth: 150,
      maxWidth: 300,
      align: "center",
      headerAlign: "center",
      renderCell: (e) => (
        <div>
          {e?.row?.fuel_vol
            ? e?.row?.fuel_vol?.toLowerCase() === "liter"
              ? e.row.liter_value + " Liter"
              : e.row.fuel_vol
            : "No fuel requested"}
        </div>
      ),
    },
    {
      field: "fuel_request_number",
      headerName: "Request number",
      flex: 1,
      minWidth: 250,
      maxWidth: 300,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "ref_no",
      headerName: "Diesel entry number",
      flex: 1,
      minWidth: 250,
      maxWidth: 300,
      align: "center",
      headerAlign: "center",
      renderCell: (e) => e.row.vehicle?.ref_no,
    },
    // {
    //   field: "exit_reference_no",
    //   headerName: "Diesel exit number",
    //   flex: 1,
    //   minWidth: 250,
    //   maxWidth: 300,
    //   align: "center",
    //   headerAlign: "center",
    //   renderCell: (e) => e.row.vehicle?.exit_reference_no || "active",
    // },
    {
      field: "ref_date",
      headerName: "Diesel entry date",
      flex: 1,
      minWidth: 150,
      maxWidth: 300,
      align: "center",
      headerAlign: "center",
      renderCell: (e) => LocalTime(e.row.vehicle?.ref_date),
    },
    {
      field: "userName",
      headerName: "Request raised by",
      flex: 1,
      minWidth: 150,
      maxWidth: 300,
      align: "center",
      headerAlign: "center",
      renderCell: (e) => e.row.user?.userName,
    },
    {
      field: "store_approver_name",
      headerName: "Approver name",
      flex: 1,
      minWidth: 150,
      maxWidth: 300,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "store_approval_time",
      headerName: "Approval time",
      flex: 1,
      minWidth: 150,
      maxWidth: 300,
      align: "center",
      headerAlign: "center",
      renderCell: (e) => LocalTime(e.row.store_approval_time),
    },
  ];

  return (
    <>
      <Loader open={loading} />
      <VehicleListSearchSection />

      <div className="hidden lg:block h-[60vh]">
        <DataGrid
          rows={filteredData?.length > 0 ? filteredData : []}
          columns={columns}
          pageSize={5}
          rowsPerPageOptions={[5]}
          getRowClassName={(row) => row?.row.approved_by_store_manager}
          getRowId={(row) => row._id}
          autoHeight
        />
      </div>

      <div className="lg:hidden block h-[90vh]">
        {filteredData?.length > 0 &&
          filteredData?.map((item, i) => (
            <Card key={i} className={`${item?.approved_by_store_manager} my-2`}>
              <CardHeader
                avatar={
                  <Avatar>
                    <LocalShippingIcon color="info" />
                  </Avatar>
                }
                action={
                  <>
                    <IconButton onClick={() => handleExpandClick(item)}>
                      <ExpandMore
                        expand={Boolean(expanded).toString()}
                        aria-expanded={Boolean(expanded).toString()}
                        aria-label="show more"
                      >
                        <ExpandMoreIcon />
                      </ExpandMore>
                    </IconButton>
                    <Button
                      disabled={item?.approved_by_store_manager !== "pending"}
                      size="small"
                      style={{ backgroundColor: "white", fontWeight: 800 }}
                      onClick={() => {
                        setOpen(true);
                        setSelectedRowId(item?._id);
                      }}
                    >
                      {item?.approved_by_store_manager}
                    </Button>
                  </>
                }
                title={item?.vehicle?.reg_no}
                subheader={item?.vehicle?.vehicle_type?.name}
              />

              {item?._id === selectedRow && (
                <Collapse in={expanded} timeout="auto" unmountOnExit>
                  <CardContent>
                    <Typography paragraph>
                      <span className="text-orange-700 pr-2">
                        {" "}
                        Working Area:
                      </span>
                      {item?.workingArea}
                    </Typography>
                    <Typography paragraph>
                      <span className="text-orange-700 pr-2">
                        Fuel request time:
                      </span>
                      {LocalTime(item?.fuel_issue_time)}
                    </Typography>

                    <Typography paragraph>
                      <span className="text-orange-700 pr-2">Driver:</span>
                      {item?.driverName}
                    </Typography>
                    <Typography paragraph>
                      <span className="text-orange-700 pr-2">
                        {" "}
                        Requested Volume:
                      </span>
                      {item?.fuel_vol}
                    </Typography>

                    <Typography paragraph>
                      <span className="text-orange-700 pr-2">
                        Approver name:
                      </span>
                      {item?.store_approver_name}
                    </Typography>
                    <Typography paragraph>
                      <span className="text-orange-700 pr-2">
                        Approval time:
                      </span>
                      {LocalTime(item?.store_approval_time)}
                    </Typography>
                  </CardContent>
                </Collapse>
              )}
            </Card>
          ))}
      </div>

      <Dialog
        sx={{ overflow: "hidden" }}
        open={open}
        onClose={() => setOpen(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <IconButton
          edge="end"
          color="inherit"
          onClick={() => {
            setOpen(false);
            setReject(false);
          }}
          aria-label="close"
          sx={{
            position: "absolute",
            top: 2,
            right: 8,
          }}
        >
          <CloseIcon />
        </IconButton>
        <DialogTitle id="alert-dialog-title">{"Are you sure ?"}</DialogTitle>

        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Do you want to Approve this request!!!
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={(e) => setReject(true)}
            size="small"
            className="pending"
          >
            Reject
          </Button>

          <Button
            onClick={() => approveRequestFunc("approved")}
            size="small"
            className="approved"
          >
            Approve
          </Button>
        </DialogActions>

        {reject && (
          <form
            onSubmit={() => approveRequestFunc("rejected")}
            className="d-flex align-items-center gap-2 p-4"
          >
            <TextField
              required
              size="small"
              type="text"
              onChange={(e) => setRejectReason(e.target.value)}
            />

            <Button
              fullWidth={false}
              size="small"
              style={{ backgroundColor: "orange" }}
              type="submit"
            >
              Submit
            </Button>
          </form>
        )}
      </Dialog>
      <ToastContainer />
    </>
  );
};

export default ManagerDashboard;
